import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";

import * as styles from "./project-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";

function ProjectPreview(props) {

  const video = props.video
  const setVideo = props.setVideo

  return (
    <div className={styles.root} onClick={() => setVideo(String(props.vimeoURL))}>
   
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(414)
              .height(Math.floor((9 / 16) * 414))
              .dpr(2)
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>

      <div className={styles.info}>
        <h3 className={styles.title}>{props.title}</h3>
        <p className={styles.client}>{props.client}</p>
      </div>

    </div>
  );
}

export default ProjectPreview;
