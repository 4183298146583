import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";

import { responsiveTitle1 } from "../components/typography.module.css";

const AboutPage = props => {


  return (
    <Layout>
      <SEO title="About" />
      <Container>
        <section className="simpleColumns">
        <div  className="simpleImage">
        <img src="https://cdn.sanity.io/images/f40aqpvf/production/dd2f863ddead64070b2f05f7e7c2af9e2c94ca1d-834x907.jpg"></img>
        <button className="simpleButton"><a href="mailto:whitney@topnotchfilm.com">Contact</a></button>
        </div>

        
        <article className="simpleArticle" >
        <p>Whitney Wolanin is a Grammy, Webby and ACM nominated film maker who specializes in narrative, music video, branded, and commercial projects.</p>
        <p>Whitney started her storytelling career at 13 as an award-winning songwriter and recording artist. Music videos were her gateway medium into the joys of being on set.</p>
        <p>Whitney received a BS in Human and Organizational Development from Vanderbilt University, which focused heavily on entrepreneurship, leadership, and psychology. Curating and leading a well-matched and motivated crew is one of her strong suits. She also went to culinary school, but that’s a story for another day.</p>
        <p> (If you don’t like improv, please skip this section):Whitney’s style is influenced greatly by having studied, taught, and performed improv comedy for over a decade. She often tries out ideas in this format and finds it to be the quickest way to get to the joke at the core of the idea.</p>
        <p>Whitney’s work has been featured on CMT, VH1, MTV, as well as broadcast television. She produced, and was 2nd Unit Director, on the indie comedy horror film, The Reenactment, starring Tony Todd, James Storm, and Megan Duffy. She is currently developing several comedy series. Whitney feels very strongly about expanding the network and talent pool of other women filmmakers. She runs a mentorship program, CamWomen, which aims to give women on-set experience and an informal but submersive education of the different roles on sets. If you’re interested in becoming a mentee or a mentor, please reach out via email.</p>
        </article>
        </section>
        
        
      </Container>
    </Layout>
  );
};

export default AboutPage;
